import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { NOT_SELECT_EVENT_CAROUSEL_ID } from '@/constants/common';
import { useAppSelector } from '@/state/hooks';
import { setItemInLocalStorage } from '@/state/localStorage';
import { Event } from '@/types/contentful';
import { pushToElevarDataLayer } from '@/utils/pushToElevarDataLayer';
import LinkInternal from '../LinkInternal';
import { EventCarouselItemUI } from './EventCarouselItemUI';
type EventCarouselItemProps = {
  event: Event;
  passedRef?: React.RefObject<HTMLAnchorElement>;
  lastItem?: boolean;
  landscape?: boolean;
  small?: boolean;
  displayDate: boolean;
  title?: string;
  index?: number;
  id?: string;
};
export const EventCarouselItem: FC<EventCarouselItemProps> = ({
  event,
  passedRef,
  lastItem,
  landscape,
  small,
  displayDate,
  title,
  index,
  id
}) => {
  const [isFocusedOrHovered, setIsFocusedOrHovered] = useState(false);
  const auth = useAppSelector(state => state.auth);
  const handleProductClick = () => {
    if (!NOT_SELECT_EVENT_CAROUSEL_ID?.includes(id!)) pushToElevarDataLayer({
      eventName: 'dl_select_item',
      auth: auth.token,
      ecommerce: {
        currencyCode: 'EUR',
        click: {
          actionField: {
            list: window.location.pathname,
            action: 'click'
          },
          products: [{
            id: event.id,
            name: event.title,
            brand: 'OnAir',
            category: event?.type,
            variant: event?.title || event.title,
            price: event?.preferredVariant?.price?.eur?.toString() || '0.0',
            compare_at_price: event?.preferredVariant?.compareAtPrice?.eur?.toString() || '0.0',
            quantity: '1',
            list: window.location.pathname,
            product_id: event.shopifyProductId,
            variant_id: 'Dummy',
            // TODO
            image: event.poster.asset?.url,
            url: window.location.href,
            position: index
          }]
        }
      }
    });
    setItemInLocalStorage('carouselTitle', title);
  };

  /**
   * Helper function to detect if the item tile is focused or hovered
   * On 'focus' or 'mouseover' events the isFocusedOrHovered property will be true
   * In the rest of the cases false
   */
  const handleSetIsFocusedOrHovered: React.FocusEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLAnchorElement> = useCallback((e): void => {
    setIsFocusedOrHovered(e.type === 'focus' || e.type === 'mouseover');
  }, [setIsFocusedOrHovered]);
  return <LinkInternal className={classNames('carousel__item', {
    'carousel__item--last': lastItem,
    'carousel__item--small': small
  })} to={`/${event.slug}/`} innerRef={passedRef} onClick={handleProductClick} onBlur={handleSetIsFocusedOrHovered} onFocus={handleSetIsFocusedOrHovered} onMouseOver={handleSetIsFocusedOrHovered} onMouseLeave={handleSetIsFocusedOrHovered} data-sentry-element="LinkInternal" data-sentry-component="EventCarouselItem" data-sentry-source-file="EventCarouselItem.tsx">
      <EventCarouselItemUI event={event} landscape={landscape} displayDate={displayDate} isFocusedOrHovered={isFocusedOrHovered} data-sentry-element="EventCarouselItemUI" data-sentry-source-file="EventCarouselItem.tsx" />
    </LinkInternal>;
};