'use client';

import React, { FC, useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import { ContentfulVideo } from '@/types/contentful';
import { parseYoutubeLink } from '@/utils/youtube';
import YoutubePlayer from '../YoutubePlayer';
import ThumbnailImage from './ThumbnailImage';
const VideoPlayer = dynamic(() => import('@/components/-ui/video-player'), {
  ssr: false
});
export type Props = ContentfulVideo & {
  autoPlay?: boolean;
  isFirstBlock?: boolean;
};
const AUTO_SCROLL_MINUS_VALUE = 128; // The watch-trailer scrolls to the element but a few pixels before it this value is how much pixels before the container elem

const VideoBlockComponent: FC<Props> = ({
  youtubeLink,
  autoPlay,
  selfHostedVideo,
  isFirstBlock
}) => {
  const youtubeRef = useRef<null | HTMLDivElement>(null);
  const youtubeId = parseYoutubeLink(youtubeLink ?? '')?.videoId;
  const thumbnailImageUrl = selfHostedVideo?.thumbnailImage?.asset?.url;
  const thumbnailImageAltText = selfHostedVideo?.thumbnailImage?.asset?.title || selfHostedVideo?.thumbnailImage?.asset?.description || '';

  // Scroll to the correct spot for YouTube if the watch-trailer query param is set
  useEffect(() => {
    if (typeof window !== 'undefined' && autoPlay) {
      const offsetTop = youtubeRef?.current?.offsetTop;
      if (offsetTop) window.scrollTo(0, offsetTop - AUTO_SCROLL_MINUS_VALUE);
    }
  }, [youtubeLink, autoPlay]);
  if (!youtubeId && !selfHostedVideo) return null;
  return <div className={classNames('container container--vertical-padding', {
    'first-block': isFirstBlock
  })} ref={youtubeRef} data-sentry-component="VideoBlockComponent" data-sentry-source-file="VideoBlockComponent.tsx">
      {selfHostedVideo && <div className="contentblock-video-container youtube-player">
          <ThumbnailImage thumbnailImageUrl={thumbnailImageUrl} alt={thumbnailImageAltText}>
            <VideoPlayer type="video" hls={selfHostedVideo.hlsUrl} dash={selfHostedVideo.dashUrl} trackingTitle={selfHostedVideo.title} />
          </ThumbnailImage>
        </div>}
      {youtubeId && !selfHostedVideo && <YoutubePlayer youtubeId={youtubeId} autoPlay={autoPlay} />}
    </div>;
};
export default VideoBlockComponent;